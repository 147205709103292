.BriefingText {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 120%;
    font-family: 'archiaregular';

    code {
        background: rgba(45, 225, 136, 0.2);
        color: white;
        padding: 15px;
        border-radius: 10px;
        white-space: pre-line;
        display: block;
    }

    blockquote {
        display: block;
        background: rgba(148, 139, 58, 0.2);
        padding: 10px;
        border-radius: 10px;
        text-align: center;
    }

    p {
        color: white;
    }
    li {
        color: white;
    }
    p a {
        color: #f98100;
    }
    h1 {
        color: #f98100;
    }
    h2 {
        color: #f98100;
    }
    h3 {
        color: #f98100;
    }
    h4 {
        color: #f98100;
    }
}
