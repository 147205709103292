@import '../../styles/variables';

.DashboardTable {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 2em;
}

.noEntries {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .MagicBookLottie {
        height: 20rem;
    }

    :nth-child(2) {
        margin-top: -2rem;
        color: $white;
    }
}

.tableContainer {
    height: inherit;
    scrollbar-width: none;
    min-width: 1200px;
}

.tableContainer::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.tableHeaderCell,
.tableHeaderProblemCell {
    border-bottom: 6px solid $collide_green !important;
}

.tableHeaderProblemCell {
    text-align: center !important;
    font-weight: bolder;
    color: $white;
}

.tableCell,
.badgesCell {
    border-bottom: 2px solid $collide_green !important;
    padding: 1.5rem;
    color: $white;
    font-weight: bold;
}

.badgesCell {
    padding: unset !important;
}

.badge_container {
    display: flex;
    justify-content: space-evenly;
}

.badge {
    position: relative;
    height: 48px;
    width: 48px;

    :first-child {
        position: absolute;
        top: 0;
        left: 0;
    }

    :nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .confetti {
        width: 10rem;
        height: 10rem;
        position: absolute;
        top: -3.5rem;
        left: -3.5rem;
        z-index: 2;
    }
}

.tableHeaderText {
    font-weight: bold;
    color: $white;
    font-size: 15px;
    text-transform: uppercase;
}

.collideText {
    font-weight: bold;
    color: $collide_green;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
}

.teamText {
    font-weight: bold;
    color: $white;
    font-size: 18px;
    text-transform: capitalize;
}
