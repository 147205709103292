.Countdown {
    .expiredNotice {
        font-size: 30px; 
        line-height: 1.2;
        font-weight: bold;
        color: #37e284;
    }

    .timer {
        font-size: 4vw;
    }
}
