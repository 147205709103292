@import './_variables';
@import './_mixins';

@font-face {
  font-family: 'archiaregular';
  src: url('font/archia-regular-webfont.eot');
  src: url('font/archia-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('font/archia-regular-webfont.woff2') format('woff2'),
       url('font/archia-regular-webfont.woff') format('woff'),
       url('font/archia-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

* {
    box-sizing: border-box;
    outline: none;
}

html,
body {
    height: 100%;
    overflow-x: hidden;
}

body {
    position: relative;
    margin: 0;
    font-family: 'archiaregular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $main-text-color;
    background-color: $main-background-color;
    font-size: $main-text-size;
    background-image: url('../resources/assets/wall.png');
    background-size: cover;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* For webkit-based browsers */
/* Track (the area on the scrollbar not covered by the thumb) */
::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar track */
  }
  
  /* Thumb (the draggable part of the scrollbar) */
  ::-webkit-scrollbar-thumb {
    background: #f98100; /* Color of the thumb */
  }
  
  /* Add hover effect */
  ::-webkit-scrollbar-thumb:hover {
    background: #f98100; /* Color on hover */
  }
  
  /* Button (the buttons at the top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    background: #f98100; /* Color of the buttons */
  }
  
  /* Track on hover */
  ::-webkit-scrollbar-track-piece:hover {
    background: #011726; /* Background color on hover */
  }

  ::-webkit-scrollbar-track-piece {
    background: #011726; /* Background color on hover */
  }
  
  /* Corner (the corner between the vertical and horizontal scrollbars) */
  ::-webkit-scrollbar-corner {
    background: #011726; /* Background color */
  }

  
