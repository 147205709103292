.HomePage {
    position: relative;
    width: 100vw;
    height: 100vh;
    font-family: 'archiaregular';
    padding: 2em;
}

.header {
    background-color: rgba(0, 0, 0, 0.75);
    height: 10vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.headerContainer {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 20px;
}

.loadingContainer {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    color: white;
}

.logo {
    font-size: 30px;
    font-family: 'archiaregular';
    color: rgb(255, 255, 255);
    line-height: 1.2;
    font-weight: bold;
    display: flex; /* Use flex display to place items in a row */
    align-items: center; /* Vertically center items in the flex container */
}

.headerImgIcon {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    animation: rotate-pause 10s infinite; /* Adjust the animation duration and other properties as needed */
}

.briefingIcon {
    position: absolute;
    height: 125px;
    width: 125px;
    animation: rotate 7s linear infinite; /* Adjust the animation duration and other properties as needed */
    right: 40px; /* Position at the right edge of the screen */
    bottom: 40px; /* Position at the bottom edge of the screen */
  }

@keyframes rotate-pause {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg); /* Rotate the element 360 degrees */
      }
}





.countdown {
    display: flex;
    align-items: center; /* Center vertically within the container */
    justify-content: space-between; /* Create space between the image and text */
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the text vertically within its container */
}

.time {
    font-size: 30px;
    line-height: 1.2;
    font-weight: bold;
    color: white;
}

.deadline {
    font-size: 16px;
    color: white;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 10vh);

    .dashboardTitle {
        font-size: 38px;
        font-weight: bolder;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        line-height: 1.2;
        text-shadow: 0px 9px 27.3px rgba(29, 29, 27, 0.79);
        animation: jungleBounceEffect 6s infinite;
    }

    @keyframes jungleBounceEffect {
        0%,
        100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-5px);
        }
    }
}

