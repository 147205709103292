@import '../../styles/_variables';
.NotFoundPage {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    

    > p {
        bottom: 38vh;
        position: absolute;
        z-index: 2;
        color: $black;
        font-family: 'archiaregular';
        font-size: 2rem;
        background-color: $collide-green;
        border-radius: 10px;
        padding: 0 1rem;
    }

    .lottieContainer {
        width: 100%;
        height: 30vw;
        display: flex;
        align-content: center;
        justify-content: center;

        .purpleLottiePlayer,
        .layeredLottiePlayer {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 30vw;
        }

        .layeredLottiePlayer {
            position: absolute;
            width: 22vw;
            z-index: 1;
        }
    }

    .lottiePlayer {
        height: 1rem;
        width: 1rem;
    }
}
